import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import "./DownloadButton.css";

export default ({ text, href, className = "", disabled = false, ...props }) => (
  <Button
    className={`DownloadButton ${className}`}
    disabled={disabled}
    target="_blank"
    href={href}
    {...props}
  >
    {text} <FontAwesomeIcon icon={faFilePdf} />
  </Button>
);
