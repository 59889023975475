import React, { Component } from "react";
import "./Agb.css";

export default class Agb extends Component {
  render() {
    return (
      <div className="Agb">
        <header>
          <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>
        </header>

        <section>
          <h4>§1 Widerrufsrecht</h4>
          <p>
            Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
            diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn
            Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht
            auszuüben, müssen Sie uns (Akademus, Prüfeninger Str. 7, 93049
            Regensburg, Telefon 0941 7977144, Fax 0941 79777399, E-Mail
            info@akademus.de) mittels einer eindeutigen Erklärung (z. B. Brief,
            Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
            widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es
            aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts
            vor Ablauf der Widerrufsfrist absenden.
            <br />
            <strong>Besondere Hinweise:</strong> Ihr Widerrufsrecht erlischt
            vorzeitig, wenn der Vertrag von beiden Seiten auf Ihren
            ausdrücklichen Wunsch vollständig erfüllt ist, bevor Sie Ihr
            Widerrufsrecht ausgeübt haben.
          </p>
        </section>

        <section>
          <h4>§2 Anmeldung</h4>
          <p>
            a) Sie können sich für den Online-Kurs online über die Homepage
            www.akademus.de/abi24 anmelden.
          </p>
          <p>
            b) Sie erhalten von uns eine schriftliche Anmeldebestätigung mit
            Details zum Seminar sowie eine Rechnung über die Kursgebühr. Der
            Rechnungsbetrag ist via PayPal direkt zu entrichten bzw. in
            Verbindung mit dem Abiturkurs per Rechnung mit zehntägigem
            Zahlungsziel zu begleichen.
          </p>
          <p>
            c) Ein genereller Anspruch auf die Teilnahme besteht nicht; wir
            behalten uns die Zulassung zur Teilnahme im Einzelfall vor.
          </p>
        </section>

        <section>
          <h4>§3 Stornierung</h4>
          <p>
            a) Nicht in Anspruch genommene Leistungen wie Tutorials oder
            Übungsblätter können nicht erstattet werden.
          </p>
          <p>
            b) Nach Ablauf des Widerrufsrechts ist eine Kostenerstattung bei
            Stornierung des Kurses nicht mehr möglich.
          </p>
        </section>

        <section>
          <h4>§4 Änderungsvorbehalte</h4>
          <p>
            Wir behalten uns vor, erforderliche inhaltliche und organisatorische
            Änderungen und Abweichungen unserer Online-Kurse durchzuführen,
            soweit diese den Gesamtcharakter des angekündigten Kurses nicht
            wesentlich verändern.
          </p>
        </section>

        <section>
          <h4>§5 Haftungsausschluss</h4>
          <p>
            a) Auch wenn sämtliches Filmmaterial, alle Aufgaben- und
            Lösungblätter sowie das Mathematik-Skript und die Karteikarten mit
            größter Sorgfalt erarbeitet wurden, können für eventuell verbliebene
            fehlerhafte Angaben und deren Folgen weder eine juristische noch
            sonst irgendeine Haftung übernommen werden.
          </p>
          <p>
            b) Die Haftung von Akademus beschränkt sich grundsätzlich nur auf
            Vorsatz und grobe Fahrlässigkeit. Dies gilt nicht für Ansprüche
            wegen Verletzung des Lebens, des Körpers oder der Gesundheit.
          </p>
          <p>
            c) Alle Parteien sind sich einig, dass Lernerfolge grundsätzlich von
            vielen Faktoren geprägt sind und seitens Akademus kein
            Prüfungserfolg geschuldet ist.
          </p>
        </section>

        <section>
          <h4>§6 Datenverarbeitung</h4>
          <p>
            Unter Beachtung der Vorschriften des Bundesdatenschutzgesetzes
            erheben und speichern wir Daten des Teilnehmers, insofern dies für
            die Abwicklung des Vertragsverhältnisses erforderlich ist. Diese
            werden nur für interne Zwecke verwendet und keineswegs an Dritte
            weitergegeben. Der Kunde hat jederzeit die Möglichkeit, über die von
            ihm gespeicherten Daten Auskunft zu erhalten sowie deren Löschung zu
            verlangen.
          </p>
        </section>

        <section>
          <h4>§7 Schlussbestimmungen</h4>
          <p>
            a) Sämtliche Tutorials, Übungsaufgaben, Lösungsblätter sowie das
            Lernskript und alle Karteikarten sind urheberrechtlich geschützt.
            Die Weitergabe oder Vervielfältigung oben genannter Werke bzw.
            einzelner Bestandteile daraus ist nur im Rahmen der Bestimmungen des
            Urheberrechtsgesetztes der Bundesrepublik Deutschland zulässig.
          </p>
          <p>
            b) Die Unwirksamkeit einzelner Bestimmungen des Seminarvertrages
            oder dieser AGB berührt die Wirksamkeit der übrigen Bestimmungen und
            den Bestand des Vertrages nicht. An die Stelle der unwirksamen
            Bestimmungen tritt eine Regelung, die in ihrem wirtschaftlichen
            Gehalt der unwirksamen am nächsten kommt. Entsprechendes gilt im
            Falle einer Lücke.
          </p>
          <p>
            c) Ausschließlicher Gerichtsstand ist – soweit gesetzlich zulässig –
            Regensburg.
          </p>
        </section>

        <footer>
          Akademus – Inh. Dipl.-Math. Nicolas Klupak (FH), Regensburg
          <br /> Stand 27.07.2018
        </footer>
      </div>
    );
  }
}
