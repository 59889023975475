import React, { Component } from "react";
import DownloadButton from "../components/DownloadButton";
import ResponsiveVideoPlayer from "../components/ResponsiveVideoPlayer";

export default class Course extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subchapter: this.getSanitizedSubchapter(
        props.chapters,
        props.match.params.chapterId,
        props.match.params.subchapterId
      )
    };
  }

  getSanitizedSubchapter(chapters, chapterId, subchapterId) {
    const chapter = chapters[chapterId] || chapters[1];
    const subchapter =
      chapter.subchapters[subchapterId] || chapter.subchapters[1];

    const { id: currentSubchapterId, ...rest } = subchapter;
    return {
      chapterId: chapter.id,
      subchapterId: currentSubchapterId,
      ...rest
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.chapterId === this.props.match.params.chapterId &&
      prevProps.match.params.subchapterId ===
        this.props.match.params.subchapterId
    ) {
      return null;
    }

    this.setState({
      subchapter: this.getSanitizedSubchapter(
        this.props.chapters,
        this.props.match.params.chapterId,
        this.props.match.params.subchapterId
      )
    });
  }

  render() {
    return (
      <div>
        <div>
          {this.state.subchapter.excerciseUrl && (
            <DownloadButton
              variant="light"
              size="lg"
              href={this.state.subchapter.excerciseUrl}
              text="Übungsblatt"
            />
          )}
          {this.state.subchapter.solutionUrl && (
            <DownloadButton
              variant="light"
              size="lg"
              href={this.state.subchapter.solutionUrl}
              text="Lösungsblatt"
            />
          )}
        </div>
        {this.state.subchapter.videoPoster && (
          <ResponsiveVideoPlayer
            url={this.state.subchapter.videoUrl}
            controls
            config={{
              file: {
                attributes: {
                  poster: this.state.subchapter.videoPoster,
                  controlsList: "nodownload"
                }
              }
            }}
          />
        )}
      </div>
    );
  }
}
