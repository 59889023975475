import { API } from "aws-amplify";
import React, { Component } from "react";
import {
  Button,
  Nav,
  Navbar,
  NavDropdown,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Redirect, Route } from "react-router-dom";
import Course from "./Course";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  async componentDidMount() {
    this.loadChapters();
  }

  async loadChapters() {
    try {
      const chapters = this.props.isAuthenticated
        ? await API.get("api", "/courses/mathe-abi")
        : await API.get("api", "/courses/mathe-abi/free");
      this.setState({ chapters });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  renderLander() {
    if (
      this.props.isAuthenticated &&
      this.props.userDetails &&
      this.props.userDetails.hasCourseAccess
    ) {
      return <Redirect to="/course/1/1" />;
    }

    return (
      <div className="lander">
        <h1>Abi24</h1>
        <h2>Dein online Mathe-Abiturkurs!</h2>
        <p>
          Wir glauben daran,
          <br />
          dass unsere Video-Tutorials dir ermöglichen, <br />
          <strong>mehr Punkte im Mathe-Abi</strong> zu erreichen.
        </p>
        <p>
          In <strong>33</strong> leicht verständlichen
          <br />
          <strong>Video-Tutorials</strong> mit allen abiturrelevanten Themen
          <br />
          bereiten wir dich optimal auf deine Prüfungen vor.
        </p>
        <div>
          <LinkContainer to="/course/mathe-abi/1/12">
            <Button variant="outline-light">Probetutorial entdecken</Button>
          </LinkContainer>
        </div>
      </div>
    );
  }

  renderSubchapterLink(chapter, subchapter, key) {
    if (subchapter.videoUrl) {
      return (
        <LinkContainer
          key={key}
          to={`/course/mathe-abi/${chapter.id}/${subchapter.id}`}
        >
          <NavDropdown.Item>{subchapter.title}</NavDropdown.Item>
        </LinkContainer>
      );
    }

    return (
      <OverlayTrigger
        key={key}
        placement="top"
        overlay={
          <Tooltip id="tooltip-disabled">freischalten mit Abi24 Abo!</Tooltip>
        }
      >
        <span>
          <LinkContainer
            to={`/course/mathe-abi/${chapter.id}/${subchapter.id}`}
          >
            <NavDropdown.Item disabled style={{ pointerEvents: "none" }}>
              {subchapter.title}
            </NavDropdown.Item>
          </LinkContainer>
        </span>
      </OverlayTrigger>
    );
  }

  renderChapterDropdown(chapter, key) {
    return (
      <NavDropdown title={chapter.title} key={key}>
        {Object.values(chapter.subchapters).map((subchapter, key) =>
          this.renderSubchapterLink(chapter, subchapter, key)
        )}
      </NavDropdown>
    );
  }

  renderChapters() {
    return (
      <div className="chapters">
        <Navbar bg="light" variant="light" expand="lg">
          <Navbar.Brand>Mathe-Abi</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-start"
          >
            {this.state.chapters && (
              <Nav>
                {Object.values(this.state.chapters).map((chapter, key) =>
                  this.renderChapterDropdown(chapter, key)
                )}
              </Nav>
            )}
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.renderChapters()}
        <Route
          path={`${this.props.match.url}/:course/:chapterId?/:subchapterId?`}
          render={props => {
            if (!this.state.chapters) {
              return "";
            }
            return <Course chapters={this.state.chapters} {...props} />;
          }}
        />
        <Route
          exact
          path={this.props.match.url}
          render={() => {
            return this.renderLander();
          }}
        />
      </div>
    );
  }
}
