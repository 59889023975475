import React from "react";
import ReactPlayer from "react-player";
import "./ResponsiveVideoPlayer.css";

export default ({ url, controls, config }) => (
  <div className="ResponsiveVideoPlayer playerWrapper">
    <ReactPlayer
      className="reactPlayer"
      url={url}
      controls={controls}
      config={config}
      width="100%"
      height="100%"
    />
  </div>
);
