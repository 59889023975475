import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Signup.css";

export default class Verify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      confirmationCode: ""
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      this.setState({ isLoading: false });

      this.props.history.push("/login");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  renderConfirmationForm() {
    return (
      <Form onSubmit={this.handleConfirmationSubmit}>
        <Form.Group controlId="email" size="large">
          <Form.Label>E-Mail</Form.Label>
          <Form.Control
            autoFocus
            value={this.state.email}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="confirmationCode" size="large">
          <Form.Label>Bestätigungscode</Form.Label>
          <Form.Control
            autoFocus
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          <Form.Text>
            Bitte rufe deine E-Mails für den Bestätigungscode ab.
          </Form.Text>
        </Form.Group>
        <LoaderButton
          block
          variant="outline-light"
          size="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Absenden"
          loadingText="Absenden…"
        />
      </Form>
    );
  }

  render() {
    return <div className="Signup">{this.renderConfirmationForm()}</div>;
  }
}
