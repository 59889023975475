const dev = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://f8x4egenya.execute-api.eu-central-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_cFASiaLBO",
    IDENTITY_POOL_ID: "eu-central-1:ccb14691-f6f6-4fb2-8eda-6290b8fc3925",
    APP_CLIENT_ID: "6rb0vuo8qb7g5ptmcrqgcoj4kc"
  }
};

const prod = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://cb39qbrn53.execute-api.eu-central-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_mnMvW4r6z",
    IDENTITY_POOL_ID: "eu-central-1:165dfe7c-3b48-4520-b6b2-5640696559ec",
    APP_CLIENT_ID: "2qecdp7up67ht58e4pp3rhkl9a"
  }
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default config;
