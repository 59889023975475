import React, { Component } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "./LoaderButton";

class CouponForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      couponCode: ""
    };
  }

  validateForm() {
    return this.state.couponCode !== "";
  }

  handleFieldChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmitClick = async event => {
    event.preventDefault();
    this.props.onSubmit(this.state.couponCode);
  };

  render() {
    const loading = this.props.isProcessing;

    return (
      <Form className="BillingForm" onSubmit={this.handleSubmitClick}>
        <Form.Group size="large" controlId="couponCode">
          <Form.Control
            type="text"
            value={this.state.couponCode}
            onChange={this.handleFieldChange}
            placeholder="Gutscheincode"
          />
        </Form.Group>
        <LoaderButton
          block
          variant="outline-light"
          size="large"
          type="submit"
          text="Gutschein einlösen"
          isLoading={loading}
          loadingText="Gutschein einlösen"
          disabled={!this.validateForm()}
        />
      </Form>
    );
  }
}

export default CouponForm;
