import { API } from "aws-amplify";
import React, { Component } from "react";
import CouponForm from "../components/CouponForm";
import "./Settings.css";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false
    };
  }

  activateCoupon(couponCode) {
    return API.post("api", "/coupons", {
      body: {
        coupon: couponCode
      }
    });
  }

  handleFormSubmit = async couponCode => {
    this.setState({ isProcessing: true });

    try {
      await this.activateCoupon(couponCode);

      this.setState({ isProcessing: false });
      this.props.refreshUserDetails();
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 400
      ) {
        alert("Gutschein nicht gültig");
      } else {
        alert(error);
      }
      this.setState({ isProcessing: false });
    }
  };

  render() {
    return (
      <div className="Settings">
        <ul>
          <li>Login: {this.props.userDetails.email}</li>
          {this.props.userDetails.hasCourseAccess && (
            <li>
              Kurszugang bis:{" "}
              {new Date(
                this.props.userDetails.subscription.accessUntil
              ).toLocaleDateString()}
            </li>
          )}
          {!this.props.userDetails.hasCourseAccess && <li>kein Kurszugang</li>}
        </ul>
        {!this.props.userDetails.hasCourseAccess && (
          <div>
            <p>
              Wenn Du einen Gutschein erhalten hast, so kannst Du diesen unten
              einlösen.
            </p>
            <CouponForm
              isProcessing={this.state.isProcessing}
              onSubmit={this.handleFormSubmit}
            />
          </div>
        )}
      </div>
    );
  }
}
