import { API, Auth } from "aws-amplify";
import React, { Component, Fragment } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
      await this.getUserDetails();
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  getUserDetails = async () => {
    if (!this.state.isAuthenticated) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      this.setState({ userDetails: await API.get("api", "/users") });
      this.setState({ isLoading: false });
    } catch (error) {
      alert(error);
    }
  };

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isAuthenticated === this.state.isAuthenticated) {
      return;
    }

    await this.getUserDetails();
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userDetails: this.state.userDetails,
      refreshUserDetails: this.getUserDetails
    };

    return (
      !this.state.isAuthenticating && (
        <div className="App container">
          <Navbar bg="light" variant="light" expand="lg" sticky="top">
            <Navbar.Brand href="/">akademus-abi24.de</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                {this.state.isAuthenticated ? (
                  <Fragment>
                    <LinkContainer to="/">
                      <Nav.Link>Zum Kurs</Nav.Link>
                    </LinkContainer>
                    {this.state.userDetails &&
                      !this.state.userDetails.hasCourseAccess && (
                        <LinkContainer to="/settings">
                          <Nav.Link>Gutschein einlösen</Nav.Link>
                        </LinkContainer>
                      )}
                    <LinkContainer to="/settings">
                      <Nav.Link>Abo Einstellungen</Nav.Link>
                    </LinkContainer>
                    <Nav.Link onClick={this.handleLogout}>Ausloggen</Nav.Link>
                  </Fragment>
                ) : (
                  <Fragment>
                    <LinkContainer to="/">
                      <Nav.Link>Zum Kurs</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/signup">
                      <Nav.Link>Registrieren</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <Nav.Link>Einloggen</Nav.Link>
                    </LinkContainer>
                  </Fragment>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes childProps={childProps} />
          <section className="Footer">
            <Navbar className="justify-content-center" variant="dark">
              <Navbar.Text>&copy; Akademus</Navbar.Text>
              <Nav>
                <Nav.Item>
                  <Nav.Link href="https://akademus.de/impressum/">
                    Impressum
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="https://akademus.de/datenschutzerklaerung/">
                    Datenschutzerklärnug
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <LinkContainer to="/agb">
                    <Nav.Link>AGB</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              </Nav>
            </Navbar>
          </section>
        </div>
      )
    );
  }
}

export default withRouter(App);
